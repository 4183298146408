import getFileFormat from '@/helpers/getFileFormat';
import getFileExtension from '@/helpers/getFileExtension';

/**
 * Получение url вложения
 * @param file {Object} - объект вложения
 * @return {string} - url вложения
 */
export const getFileUrl = (file) => {
  const fileName = encodeURI(file.fileName);
  return `${window.location.origin}/api/tickets/${file.fileUrl}&attachmentName=${fileName}`;
};

/**
 * Форматирование массива вложений
 * @param array {array} - массив, который нужно Форматирование
 * @return {array} - отформатированный массив вложений
 */
export const fileListMap = (array) => array.map((el) => ({
  name: el.fileName,
  url: getFileUrl(el),
  size: el.fileSize,
  format: getFileFormat(el.fileName),
  extension: getFileExtension(el.fileName),
  date: el.createdAt,
}));

/**
 * Получение списка файлов из буфера обмена
 * @param event {Object} - событие 'paste'
 * @return {array} - массив файлов
 */
export const pasteFilesFromClipboard = (event) => {
  const { items } = (event.clipboardData || event.originalEvent.clipboardData);
  const blobs = [];
  Array.prototype.forEach.call(items, (i) => {
    if (i.kind === 'file') {
      blobs.push(i.getAsFile());
    }
  });

  return blobs;
};
