<template>
  <div class="modal-watchers-wrapper">
    <esmp-modal
      v-model="showModal"
      title="Добавление/удаление наблюдателей"
      :width="600"
      ok-text="Сохранить"
      cancel-text="Нет, я передумал"
      @on-ok="okHandler"
      @on-hidden="closeHandler"
    >
      <hm-search
        class="customer-user__search"
        placeholder="Поиск сотрудников"
        clear-on-select
        @input="onInput"
      />
      <template v-for="(user, index) in userList">
        <div
          :key="`${user.login}_${index}`"
          class="customer-user__wrapper"
        >
          <div class="customer-user">
            <esmp-user-avatar
              class="customer-user__avatar"
              :username="user.fullName"
              :image-url="user.photoURL"
            />
            <div class="customer-user__info">
              <div class="customer-user__name">
                {{ user.fullName }}
                <span v-if="user.email" class="customer-user__email">{{ user.email }}</span>
              </div>
              <div class="customer-user__appointment-name">
                {{ user.appointmentName }}
              </div>
            </div>
          </div>
          <div
            @click="removeUser(user.login)"
            class="customer-user__delete"
          >
            <esmp-icon name="trash" />
          </div>
        </div>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';
import HmSearch from '../../hm-form/controls/HmSearch.vue';

export default {
  name: 'ModalWatchers',
  components: { HmSearch },
  props: {
    visible: {
      type: Boolean,
    },
    watchers: {
      type: Array,
      default: () => [],
    },
    isCreatedMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userList: [...this.watchers],
      showConfirm: false,
    };
  },
  computed: {
    ticketId() {
      return this.$route.params.id;
    },
    showModal: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    removeUser(login) {
      this.userList = this.userList.filter((u) => u.login !== login);
    },
    onInput(v) {
      if (!v[0].login) return false;
      const existedUser = this.userList.find((u) => u.login === v.login);
      if (!existedUser) {
        this.userList.push(v[0]);
      }
      return true;
    },
    okHandler() {
      if (this.isCreatedMode) {
        this.$emit('update-watcher-list', this.userList);
        this.$emit('hide-modal');
      } else {
        this.setLoading({ key: 'app', value: true });
        this.$API.ticket.setWatchers(this.ticketId, this.userList.map((el) => ({
          login: el.login,
          fullName: el.fullName,
          employeeNumber: el.employeeNumber,
        })), { source: 'otrs1' }).then(() => {
          Hub.$emit('update-ticket');
        }).finally(() => this.setLoading({ key: 'app', value: false }));
      }
    },
    closeHandler() {
      this.counter += 1;
      this.userList = [];
      this.$emit('hide-modal', { modalName: this.$options.name, newValue: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  &__search {
    margin: 20px 0 0 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0 0;
  }
  &__avatar {
    margin: 0 20px 0 0;
    width: 40px;
    height: 40px;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
  }
  &__email {
    display: inline-block;
    margin: 0 0 0 16px;
    color: rgba(16, 24, 40, 0.4);
  }
  &__appointment-name {
    color: $color-primary-1-day;
  }
  &__email, &__appointment-name {
    font-size: 12px;
    line-height: 16px;
  }
  &__delete {
    cursor: pointer;
    margin-left: auto;
  }
}
</style>
