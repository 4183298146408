<template>
  <div class="feasts">
    <div class="feasts__list" v-if="feasts && feasts.length">
      <esmp-select
        v-model="selectedFeast"
        placeholder="Праздник"
        clearable
        @on-select="selectFeast"
      >
        <esmp-select-option
          v-for="feast in feasts"
          :value="feast.id"
          :key="feast.id"
        >
          {{ feast.title }}
        </esmp-select-option>
      </esmp-select>
    </div>
    <div
      class="feasts__current-item feast"
      v-if="currentFeast"
      ref="feast"
    >
      <h2 class="feast__title">
        {{ currentFeast.title }}
      </h2>
      <p>Выберите открытку*</p>
      <swiper
        class="feast__images"
        :options="swiperOption"
        :key="currentFeast.id"
      >
        <swiper-slide
          :class="['feast__image', {'feast__image--selected': selectedImageId === image.fileId}]"
          v-for="image in currentFeast.postcards"
          :key="`image-${image.fileId}`"
        >
          <img
            :src="image.url"
            :alt="currentFeast.title"
            @click="selectImageId(image.fileId)"
          >
        </swiper-slide>
        <div
          v-if="currentFeast.postcards.length > swiperOption.slidesPerView"
          class="swiper-button-prev feast__button feast__button--prev"
          slot="button-prev"
        />
        <div
          v-if="currentFeast.postcards.length > swiperOption.slidesPerView"
          class="swiper-button-next feast__button feast__button--next"
          slot="button-next"
        />
      </swiper>
      <div class="feast__checkbox">
        <esmp-checkbox v-model="isAnonymous">
          Отправлять анонимно?
        </esmp-checkbox>
      </div>
      <textarea
        class="feast__message"
        placeholder="Текст поздравления"
        v-model="message"
      />
      <search-user
        :user.sync="recipientEmail"
        placeholder="Поиск сотрудников*"
      />
      <esmp-button @click="sendPostcard">
        Отправить поздравление
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import SearchUser from '@/components/search-lists/search-user';

export default {
  name: 'Feasts',
  components: {
    SearchUser,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      currentPage: 0,
      isAnonymous: false,
      message: '',
      recipientEmail: '',
      selectedImageId: null,
      selectedFeast: null,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 16,
        autoHeight: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  watch: {
    currentPage(val) {
      this.getFeasts(val - 1);
    },
  },
  computed: {
    ...mapState('feasts', ['feasts', 'currentFeast', 'pageCount']),
    ...mapGetters('user', ['selectedOrLoggedInUser']),
    isValidatedPostcardForm() {
      return this.selectedImageId && this.recipientEmail;
    },
  },
  methods: {
    ...mapActions('feasts', [
      'getFeasts',
      'getFeast',
      'clearFeast',
    ]),
    ...mapActions('system', ['setLoading']),
    async selectFeast(selectedFeast) {
      this.clearData();
      await this.getFeast(selectedFeast.value);
      this.$refs.feast.scrollIntoView({ block: 'center', behavior: 'smooth' });
    },
    selectImageId(imageId) {
      this.selectedImageId = imageId;
    },
    sendPostcard() {
      if (!this.isValidatedPostcardForm) {
        this.$EsmpNotify.$show('Заполните обязательные поля формы', 'error');
        if (!this.selectedImageId) this.$EsmpNotify.$show('Открытка не выбрана', 'error');
        return false;
      }
      const postcard = {
        feastId: this.currentFeast.id,
        fileId: this.selectedImageId,
        recipientEmail: this.recipientEmail,
        message: this.message,
        isAnonymous: this.isAnonymous,
      };
      if (!this.isAnonymous) {
        postcard.senderFullName = this.selectedOrLoggedInUser.fullName;
      }
      this.setLoading({ key: 'page', value: true });
      return this.$API.feasts.sendPostcard(postcard).then(() => {
        this.$EsmpNotify.$show('Поздравление отправлено', 'success');
        this.setLoading({ key: 'page', value: false });
        this.clearData();
      }).catch((e) => e);
    },
    clearData() {
      this.isAnonymous = false;
      this.message = '';
      this.recipientEmail = '';
      this.selectedImageId = null;
    },
  },
  created() {
    this.getFeasts();
  },
  beforeDestroy() {
    this.clearFeast();
  },
};
</script>

<style lang="scss">
.feasts {

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  &__item {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    background: $color-grayscale-05;
    border-radius: 12px;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    flex-grow: 1;
    margin-bottom: 6px;
    transition: opacity $base-animation-time;

    &--selected {
      background-color: rgba(148, 102, 255, 0.1);
    }

    @include hover(true) {
      opacity: .8;
    }
  }
}

.feast {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &__title {
    margin-bottom: 24px;
  }

  &__images {
    margin-bottom: 24px;
    width: 100%;
  }

  &__image {
    /*height: 200px;*/
    border-radius: $base-border-radius;
    border: 2px solid $color-client-portal-logo-op-60;
    overflow: hidden;
    transition: border-color $base-animation-time;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
    @include hover(true) {
      border-color: $color-primary-1-day;
    }
    &--selected {
      border-color: $color-primary-1-day;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $color-client-portal-logo-op-60;
        opacity: .5;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__button {
    width: 27px;
    height: 44px;
    &::after {
      content: '';
      color: $color-client-portal-logo;
      width: 27px;
      height: 27px;
      border-top: 5px solid #6E18C0;
      border-right: 5px solid #6E18C0;
    }
    &--prev {
      &::after {
        transform: rotate(-135deg);
      }
    }
    &--next {
      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__checkbox {
    margin-bottom: 24px;
  }

  &__message {
    width: 100%;
    font-weight: 400;
    font-family: $base-font-family;
    color: $color-text;
    margin-bottom: 24px;
    border: none;
    outline: none;
    min-height: 48px;
    font-feature-settings: "tnum", "lnum";
    font-size: 16px;
    line-height: 24px;
    padding: 12px;
    border-radius: $base-border-radius;
    background-color: $color-black-op-05;

    &::placeholder {
      color: $color-black-op-75;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: "tnum", "lnum";
    }
  }

  .search-user {
    margin-bottom: 24px;
  }
}
</style>
