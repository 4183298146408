export const USER_INFO = {
  fullName: 'Фамилия, имя, отчество работника',
  employeeNumber: 'Таб. номер',
  company: 'Филиал',
  corpStructure: 'Структурное подразделение (полный путь)',
  appointmentName: 'Должность',
  mobilePhone: 'Контактный телефон',
  email: 'Электронная почта',
};

export const TYPE = {
  individualId: 'Индивидуальное',
  groupId: 'Групповое',
};
