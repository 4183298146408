<template>
  <div class="page page--bottom-indent page-create-ticket">
    <esmp-collapser
      v-if="isNewsShown"
      class="page-create-ticket__news"
      title="Важная новость!"
      icon="attention"
      type="white"
      :is-icon-rotatable="false"
      :is-expanded.sync="isExpandedNews"
    >
      <div
        v-for="item in serviceNews"
        :key="`ticket-news-item_${item.id}`"
        class="ticket-news-item"
      >
        <picture class="ticket-news-item__image">
          <esmp-link :to="item.url">
            <img :src="item.mainImageUrl" :alt="item.title">
          </esmp-link>
        </picture>

        <div class="ticket-news-item__body">
          <h4>{{ item.title }}</h4>

          <p>{{ item.text }}</p>

          <esmp-link :to="item.url">
            Подробнее
          </esmp-link>
        </div>
      </div>
    </esmp-collapser>

    <component
      ref="customServiceComponent"
      v-if="customService && customService.isFull"
      :is="customService.componentName"
      :config="customService.config"
      :checklist="checklist"
      v-model="model"
    />

    <template v-else>
      <div
        :class="[
          'create-ticket',
          { 'disabled-block' : isFormDisabled },
        ]"
      >
        <category-tree
          :is-form-disabled="isFormDisabled"
          :has-current-service="hasCurrentService"
          @get-checklist="getChecklist"
          v-show="!isFeast && !isInterfaceHidden"
        />

        <template v-if="hasCurrentService || isInterfaceHidden">
          <div v-if="!isInterfaceHidden" class="create-ticket__header">
            <div class="create-ticket__header-sub-title">
              {{ currentHeader }}
            </div>
            <favorite-service-toggle-button :service-id="serviceId" :source-system="sourceSystem" />
          </div>
          <h2
            v-if="isTitleShown"
            class="create-ticket__title"
          >
            {{ currentServiceName }}
          </h2>

          <div
            v-if="!isInterfaceHidden"
            class="create-ticket__desc"
            v-html="description"
          />
          <div class="create-ticket__tags" v-if="!isFeast && !isInterfaceHidden">
            <esmp-tag
              v-if="currentTypeName"
              color="yellow"
              :size="tagSize"
            >
              {{ currentTypeName }}
            </esmp-tag>
            <esmp-tag
              v-if="currentCategoryName"
              color="green"
              :size="tagSize"
            >
              {{ currentCategoryName }}
            </esmp-tag>
            <esmp-tag
              v-if="currentGroupName"
              color="blue"
              :size="tagSize"
            >
              {{ currentGroupName }}
            </esmp-tag>
          </div>
          <feasts v-if="isFeast" />
          <form
            @submit="onSubmit"
            :class="[ 'create-ticket__form', { 'create-ticket__form--frame-mode': isInterfaceHidden }]"
            ref="form"
            v-if="!isFeast"
          >
            <h3 class="create-ticket__form-title">
              Анкета заявки
            </h3>
            <esmp-checkbox
              v-if="formOptions.showGroupTaskButton && !isInterfaceHidden && config.isCreateGroupTicketAvailable"
              v-model="groupTicket"
              @on-change="toggleGroupTicketModal"
              class="create-ticket__form-group-checkbox"
            >
              Групповая заявка
            </esmp-checkbox>
            <div
              v-if="customerUsers && customerUsers.length"
              class="customer-users"
            >
              <div
                v-for="user in customerUsers"
                :key="user.id"
                class="customer-users__element"
              >
                <esmp-user-avatar
                  :username="user.fullName"
                  :image-url="user.photoURL"
                  class="customer-users__element-avatar"
                />
                <span class="customer-users__element-fullname">
                  <span
                    v-if="user.lastName"
                    class="customer-users__element-lastname"
                  >
                    {{ user.lastName }}
                  </span>
                  <span
                    v-if="user.name"
                    class="customer-users__element-name"
                  >
                    {{ user.name }}
                  </span>
                </span>
              </div>
              <esmp-button
                class="customer-users__add-user"
                @click="groupTicketModal = true"
                size="small"
                view="ghost"
                icon="plus"
                icon-position="left"
              >
                Добавить еще
              </esmp-button>
            </div>
            <esmp-modal
              v-model="groupTicketModal"
              title="Групповая заявка"
              width="600"
              ok-text="Сохранить"
              cancel-text="Закрыть"
              :disabled-ok-button="disableAddUser"
              @on-ok="saveGroupTicketUsers"
              @on-cancel="hideGroupTicketModal"
            >
              <esmp-loader v-show="groupTicketModalLoading" fix />
              <div class="create-ticket__hint">
                Для каждого сотрудника будет создана отдельная заявка
              </div>
              <esmp-checkbox
                v-if="groupTicket && !selectedUser"
                v-model="isIncludedCurrentUserInGroupTicket"
              >
                Включать в групповую заявку сотрудника, подающего заявку
              </esmp-checkbox>

              <esmp-checkbox
                class="create-ticket__fired-user"
                v-model="searchFired"
              >
                Поиск по уволенным сотрудникам
              </esmp-checkbox>
              <hm-search
                class="customer-user__search"
                :fired="searchFired"
                multiple
                placeholder="Поиск сотрудников"
                hide-results
                v-model="searchCustomerUsers"
                @input="checkUsers"
                ref="searchUserSelect"
              />
              <template v-for="(user, index) in customerUsers">
                <div
                  :key="`${user.login}_${index}`"
                  class="customer-user__wrapper"
                >
                  <div class="customer-user">
                    <esmp-user-avatar
                      :username="user.fullName"
                      :image-url="user.photoURL"
                      class="customer-user__avatar"
                    />
                    <div class="customer-user__info">
                      <div
                        :class="{
                          'customer-user__name': true,
                          'customer-user__name_no-access': !user.hasAccess
                        }"
                      >
                        <span class="customer-user__name-fio">{{ user.fullName }}</span>
                        <span v-if="user.email" class="customer-user__email">{{ user.email }}</span>
                      </div>
                      <div class="customer-user__appointment-name">
                        {{ user.appointmentName }}
                      </div>
                      <div
                        v-if="!user.hasAccess"
                        class="customer-user__no-access"
                      >
                        Выбранная услуга недоступна для сотрудника
                      </div>
                    </div>
                  </div>
                  <div
                    @click="removeUser(user.login)"
                    class="customer-user__delete"
                  >
                    <esmp-icon name="trash" />
                  </div>
                </div>
              </template>
            </esmp-modal>
            <component
              ref="customServiceComponent"
              v-if="model && customService && !customService.isFull && !customService.isAction"
              :is="customService.componentName"
              :config="customService.config"
              :checklist="checklist"
              v-model="model"
            />
            <div v-show="showBaseChecklistTitle" class="create-ticket__base-controls">
              <validation-observer ref="baseControls1">
                <hm-input
                  v-if="showBaseChecklistTitle"
                  class="create-ticket__base-control"
                  name="Тема"
                  rules="required"
                  placeholder="Тема *"
                  v-model="baseChecklist.title"
                />
              </validation-observer>
            </div>
            <hm-form
              v-if="model"
              ref="myform"
              :fields="checklist"
              v-model="model"
            />
            <div class="create-ticket__base-controls">
              <validation-observer ref="baseControls2">
                <hm-input
                  v-if="showBaseChecklistPhone"
                  class="create-ticket__base-control"
                  name="Контактный телефон"
                  placeholder="Контактный телефон"
                  v-model="baseChecklist.phone"
                />
                <hm-text-area
                  v-if="showBaseChecklistDescription"
                  class="create-ticket__base-control"
                  name="Дополнительная информация"
                  rules="required"
                  placeholder="Дополнительная информация *"
                  v-model="baseChecklist.description"
                />
                <hm-uploader
                  v-if="showBaseChecklistFiles"
                  class="create-ticket__base-control"
                  name="Файл"
                  rules="size:147456000"
                  v-model="baseChecklist.files"
                />
              </validation-observer>
            </div>
          </form>
          <esmp-button
            v-if="isWatcherShown"
            class="create-ticket__add-watchers"
            @click="showWatcherModal"
            view="function"
          >
            Добавить / Удалить наблюдателей
          </esmp-button>
          <div v-if="watcherList.length" class="create-ticket__watchers">
            <h4 class="create-ticket__watchers-label">
              Список наблюдателей:
            </h4>
            <div class="create-ticket__watcher-list">
              <div
                v-for="watcher in watcherList"
                class="create-ticket__watcher"
                :key="watcher.id"
              >
                <div class="create-ticket__watcher-name" @click="showWatcherInfoModal(watcher)">
                  <esmp-user-avatar
                    :username="watcher.fullName"
                  />
                  {{ watcher.fullName }}
                </div>
                <esmp-button
                  @click="removeWatcher(watcher.login)"
                  icon="trash"
                  view="function"
                  class="create-ticket__watcher-remove"
                />
              </div>
            </div>
          </div>
          <modal-watchers
            :visible="watcherModal"
            :watchers="watcherList"
            is-created-mode
            :key="`ModalWatchers${watcherModal}`"
            @update-watcher-list="updateWatcherList"
            @hide-modal="watcherModal = false"
          />
          <modal-user-info
            v-if="watcherInfo"
            :is-show="watcherInfoModal"
            :current-user-info="watcherInfo"
            @hide-modal="watcherInfoModal = false"
          />
          <esmp-modal
            v-model="templateModal"
            title="Сохранение шаблона заявки"
            @on-ok="saveTemplate"
            @on-cancel="templateModal = false"
          >
            <esmp-input label="Название" v-model="templateName" />
          </esmp-modal>

          <esmp-modal
            v-model="isRedirectModalShowed"
            title="Важная информация!"
            ok-text="Перейти к подаче заявки"
            @on-ok="onApproveRedirectModal"
            @on-cancel="onCancelRedirectModal"
          >
            <p>
              Для создания заявки по этой услуге необходимо нажать на кнопку
              "Перейти к подаче заявки"
            </p>
          </esmp-modal>
        </template>
      </div>

      <div
        v-if="isActionsShown"
        :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
      >
        <esmp-button
          class="page__action-button"
          v-if="formOptions.isCreateTicketButtonShown"
          type="submit"
          :size="actionButtonSize"
          :disabled="isFormDisabled"
          @click="onSubmit"
        >
          Отправить
        </esmp-button>

        <esmp-button
          class="page__action-button"
          v-if="formOptions.isCreateTicketButtonShown"
          view="outline"
          :size="actionButtonSize"
          :disabled="isFormDisabled"
          @click="templateModal = true"
        >
          Использовать как шаблон
        </esmp-button>
        <component
          ref="customServiceComponent"
          v-if="model && customService && !customService.isFull && customService.isAction"
          :is="customService.componentName"
          :config="customService.config"
          :checklist="checklist"
          v-model="model"
          class="page__action-button"
        />
      </div>
    </template>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters, mapState } from 'vuex';
import pick from 'lodash/pick';
import { CUSTOM_SERVICES } from '@/constants/ticket';
import { getNewsArticlePath } from '@/helpers/pathGenerator';
import { pasteFilesFromClipboard } from '@/helpers/attachments';
import Hub from '@/plugins/event-hub';
import HmForm from '@/components/hm-form/';
import mappedFields from '@/components/hm-form/helpers/mappedFields';
import mappedFieldsForTemplate from '@/components/hm-form/helpers/mappedFieldsForTemplate';
import createDefaultModel from '@/components/hm-form/helpers/createDefaultModel';
import createModelFromTemplate from '@/components/hm-form/helpers/createModelFromTemplate';
import createModelFromTicket from '@/components/hm-form/helpers/createModelFromTicket';
import getFileList from '@/components/hm-form/helpers/getFileList';
import getValidChecklistFields from '@/components/hm-form/helpers/getValidChecklistFields';
import HmSearch from '@/components/hm-form/controls/HmSearch.vue';
import HmInput from '@/components/hm-form/controls/HmInput.vue';
import HmTextArea from '@/components/hm-form/controls/HmTextArea.vue';
import HmUploader from '@/components/hm-form/controls/HmUploader.vue';
import BusinessCard from '@/components/business-card';
import BusinessTrip from '@/components/business-trip';
import ExternalTraining from '@/components/external-training';
import Feasts from '@/components/feasts';
import FavoriteServiceToggleButton from '@/components/favorite-service-toggle-button';
import CategoryTree from '@/components/category-tree';
import ModalWatchers from '@/components/modals/modal-watchers';
import ModalUserInfo from '@/components/modals/modal-user-info';
import DismissalTemplate from '@/components/dismissal-template';

export default {
  name: 'CreateTicket',
  components: {
    HmSearch,
    HmInput,
    HmTextArea,
    HmUploader,
    FavoriteServiceToggleButton,
    CategoryTree,
    HmForm,
    BusinessCard,
    BusinessTrip,
    ExternalTraining,
    Feasts,
    ModalWatchers,
    ModalUserInfo,
    DismissalTemplate,
  },
  data() {
    return {
      customerUsers: [],
      searchCustomerUsers: [],
      searchFired: false,
      groupTicket: false,
      groupTicketModal: false,
      groupTicketModalLoading: false,
      isIncludedCurrentUserInGroupTicket: false,
      templateModal: false,
      watcherModal: false,
      watcherInfoModal: false,
      isRedirectModalShowed: false,
      valid: true,
      model: undefined,
      checklist: [],
      formOptions: {}, // настройки формы, которые приходят с бека
      baseChecklist: {
        title: '',
        description: '',
        phone: '',
        files: [],
      },
      description: '',
      formId: '',
      formVersionId: '',
      isMassFail: false,
      isAffectsCompanyCustomers: false,
      ticketType: null,
      templateName: '',
      serviceNews: [],
      watcherList: [],
      watcherInfo: null,
      isExpandedNews: true,
      formType: '',
    };
  },
  computed: {
    ...mapState('viewOptions', ['windowSizes']),
    ...mapState('system', ['loading', 'config']),
    ...mapState('services', ['favoriteServices', 'services']),
    ...mapState('user', ['loggedInUser', 'selectedUser']),
    ...mapState('ticket', ['customServiceFiles', 'tree', 'form', 'filesForSend']),
    ...mapState('search', ['searchLogId']),
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('userTemplates', ['templates']),
    ...mapState('document', ['documentAttachments']),
    ...mapGetters('user', ['selectedOrLoggedInUser']),
    ...mapGetters('ticket', ['currentTypeName', 'currentCategoryName', 'currentGroupName', 'currentServiceName']),
    ...mapGetters('viewOptions', ['isInterfaceHidden']),
    tagSize() {
      return this.windowSizes.isPhonePortrait ? 'small' : 'medium';
    },
    actionButtonSize() {
      return this.windowSizes.isPhonePortrait ? 'small' : 'medium';
    },
    isFormDisabled() {
      return Boolean(this.formOptions.isNeedRedirect);
    },
    contactUser() {
      return {
        id: this.loggedInUser.userInfoByMasterSystems[this.sourceSystem].id,
        login: this.loggedInUser.userInfoByMasterSystems[this.sourceSystem].login,
      };
    },
    serviceId() {
      return this.$route.params.serviceId;
    },
    sourceSystem() {
      return this.$route.params.source;
    },
    templateId() {
      return this.$route.query.templateId;
    },
    ticketId() {
      return this.$route.query.ticketId;
    },
    checklistFormId() {
      return this.$route.query.formId;
    },
    customerLogin() {
      return this.$route.query.customerLogin;
    },
    isResubmit() {
      return this.$route.query.isResubmit;
    },
    disableAddUser() {
      return this.customerUsers.some((el) => !el.hasAccess);
    },
    customService() {
      const serviceParams = this.$route.params;
      const customService = Object.values(CUSTOM_SERVICES).find(
        (service) => service.src === serviceParams.source && service.serviceId === serviceParams.serviceId,
      );

      return customService || null;
    },
    hasCurrentService() {
      return !isEmpty(this.tree.fourthLevel);
    },
    isFeast() {
      if (this.serviceId && this.config?.postcardServices) {
        return !!this.config.postcardServices.find((postcard) => postcard.id === this.serviceId);
      }
      return false;
    },
    currentHeader() {
      return this.isFeast ? 'Отправить поздравление' : 'Создание заявки';
    },

    showBaseChecklistTitle() {
      return !this.formOptions.isTitleHidden;
    },
    showBaseChecklistPhone() {
      return !this.formOptions.isPhoneHidden;
    },
    showBaseChecklistDescription() {
      return !this.formOptions.isDescriptionHidden;
    },
    showBaseChecklistFiles() {
      return this.formOptions.showAttachmentsUploader;
    },
    isWatcherShown() {
      return this.config?.ticketWatchersAvailable && !this.isFeast && !this.isInterfaceHidden;
    },
    isActionsShown() {
      return !this.loading.page && this.hasCurrentService && !this.isFeast && !this.isInterfaceHidden;
    },
    isNewsShown() {
      return this.serviceNews.length && this.serviceId && !this.isInterfaceHidden;
    },
    isTitleShown() {
      return this.currentServiceName && !this.isInterfaceHidden;
    },

    firstUploaderControl() {
      let firstUploaderControl = null;
      if (this.checklist) {
        const findUploader = (field) => {
          if (field.class === 'uploader') {
            firstUploaderControl = field;
            return true;
          }

          if (field.class === 'group') {
            return field.childs.some(findUploader);
          }

          return false;
        };
        this.checklist.some(findUploader);
      }

      return firstUploaderControl;
    },
    isRequestFrom() {
      return this.customerLogin && this.customerLogin !== this.loggedInUser.login;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('services', ['getFavorites']),
    ...mapActions('search', ['clearSearchLogId']),
    ...mapActions('user', ['clearSelectedUser', 'setSelectedUser']),
    ...mapActions('ticket', ['clearCustomServiceFiles', 'fetchUserAdditionalInfo', 'setForm', 'setFilesForSend']),

    removeUser(login) {
      this.$refs.searchUserSelect.removeUser(login);
      this.customerUsers = this.customerUsers.filter(
        (el) => el.login !== login,
      );
    },
    async addUser(login) {
      this.groupTicketModalLoading = true;
      const fullInfo = await this.$API.user.info(login).then(({ data }) => data);
      const access = await this.$API.checklist.checkGroupTaskAccess({
        customerId: fullInfo.userInfoByMasterSystems[this.sourceSystem].id,
        customerLogin: fullInfo.userInfoByMasterSystems[this.sourceSystem].login,
        formVersionId: this.formVersionId,
        serviceId: this.serviceId,
        source: this.sourceSystem,
        contactId: this.contactUser.id,
      }).then(({ data }) => data).finally(() => {
        this.groupTicketModalLoading = false;
      });
      this.customerUsers.push({
        ...fullInfo,
        hasAccess: access,
      });
    },
    checkUsers(v) {
      if (v && v.length) {
        const notAddedUsers = v.filter((el) => !this.customerUsers.find((item) => item.login === el.login));
        if (notAddedUsers && notAddedUsers.length) {
          notAddedUsers.forEach((el) => {
            this.addUser(el.login);
          });
        } else {
          this.customerUsers = this.customerUsers.filter((el) => v.find((item) => item.login === el.login));
        }
      } else {
        this.customerUsers = [];
      }
    },
    saveGroupTicketUsers() {
      this.hideGroupTicketModal();
    },
    hideGroupTicketModal() {
      this.groupTicketModal = false;
      if (this.disableAddUser) {
        this.customerUsers = this.customerUsers.filter((el) => el.hasAccess);
      }
      if (!this.customerUsers || !this.customerUsers.length) {
        this.groupTicket = false;
      }
    },
    toggleGroupTicketModal(val) {
      if (val) this.groupTicketModal = !this.groupTicketModal;
      else this.customerUsers = [];
    },
    async validate() {
      let value = true;
      await Promise.all([
        this.$refs.baseControls1.validate(),
        this.$refs.myform.validate(),
        this.$refs.baseControls2.validate(),
      ]).then((result) => {
        const hasErros = result.some((r) => !r);
        value = !hasErros;
      });
      return value;
    },
    async onSubmit(e) {
      if (this.isFormDisabled) return;

      e.preventDefault();
      this.valid = await this.validate();

      if (!this.valid) {
        // штатный nextTick не подходит
        this.$forceNextTick(() => {
          const target = this.$refs.form.querySelector('.hm-form__element-wrapper_error');
          if (target) {
            target.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        });
      } else {
        const documents = {};
        if (this.documentAttachments) {
          Object.entries(this.documentAttachments).forEach(([key, values]) => {
            values.forEach((files, index) => {
              files.forEach((file, i) => {
                documents[`${key}-${index}-${i}`] = file;
              });
            });
          });
        }

        this.getForm();

        const data = new FormData();
        data.append('createTicketEntity', JSON.stringify(this.form));

        this.filesForSend.forEach((file) => {
          data.append('attachments', file, file.name);
        });
        Object.entries(documents).forEach(([key, file]) => {
          data.append(key, file, file.name);
        });
        this.setLoading({ key: 'page', value: true });
        this.$API.ticket.createTicket(data).then((res) => {
          this.checkResponse(res.data);
        }).finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
      }
    },
    checkResponse(data) {
      const hasErrors = data?.createErrors?.length;
      const hasSuccess = data?.createdTickets?.length;

      if (!hasErrors && hasSuccess) {
        const successTicketsCustomers = data.createdTickets.map((t) => t.customer.email);
        let message = 'Заявка создана';
        if (data.createdTickets.length > 1) {
          message = `Ваша заявки созданы для ${successTicketsCustomers.join(', ')}`;
        }
        this.$EsmpNotify.$show(message, 'success');
      } else if (hasErrors && hasSuccess) {
        // в случае групповых заявок
        const successTicketsCustomers = data.createdTickets.map((t) => t.customer.email);
        this.$EsmpNotify.$show(`Ваша заявки созданы для ${successTicketsCustomers.join(', ')}`, 'success');
        data.createErrors.forEach((e) => {
          this.$EsmpNotify.$show(`${e.login} - ${e.error}`, 'error');
        });
      } else {
        this.$EsmpNotify.$show('Неизвестная ошибка', 'error');
      }

      if (hasSuccess) {
        this.clearCustomServiceFiles();
        if (this.watcherList?.length) {
          data.createdTickets.forEach((ticket) => {
            this.$API.ticket.setWatchers(ticket.id, this.watcherList.map((el) => ({
              login: el.login,
              employeeId: el.employeeId,
            })), { source: 'otrs1' });
          });
        }
        this.$router.push({
          name: 'Tickets',
          params: { category: 'active' },
        });
        if (this.isResubmit && this.isRequestFrom) {
          this.clearSelectedUser();
        }
      }
    },
    setFormOptions(data) {
      this.formOptions = {
        isNeedRedirect: Boolean(data.isNeedRedirect),
        showGroupTaskButton: Boolean(data.showGroupTaskButton),
        isTitleHidden: Boolean(data.isTitleHidden),
        isDescriptionHidden: Boolean(data.isDescriptionHidden),
        isPhoneHidden: Boolean(data.isPhoneHidden),
        showAttachmentsUploader: Boolean(data.showAttachmentsUploader),
        isCreateTicketButtonShown: Boolean(data.isCreateTicketButtonShown),
      };
      // удалить defaultDescription, когда SM тикеты исчезнут
      this.description = data.serviceDescription || data.defaultDescription;
      this.checklist = Object.freeze(getValidChecklistFields(data.fields));
      this.formId = data.id || '';
      this.formVersionId = data.formVersionId;
      this.formType = data.formType;
    },
    getChecklist() {
      const isResubmit = this.ticketId && this.customerLogin;
      const getFavoriteServices = !this.favoriteServices ? this.getFavorites() : null;
      let getCheckList;
      if (isResubmit) {
        getCheckList = this.$API.checklist.getCheckListFromExistedTicket({
          ticketId: this.ticketId,
          customerLogin: this.customerLogin,
          source: this.sourceSystem,
        }).then(({ data }) => {
          this.setFormOptions(data);
        });
      } else if (this.checklistFormId) {
        getCheckList = this.$API.checklist.getCheckListByFormId(this.serviceId, {
          formId: this.checklistFormId,
          source: this.sourceSystem,
        }).then(({ data }) => {
          this.setFormOptions(data);
        });
      } else {
        getCheckList = this.$API.checklist.getCheckList(this.serviceId, {
          customerId: this.selectedOrLoggedInUser.userInfoByMasterSystems[this.sourceSystem].id,
          contactId: this.contactUser.id,
          customerLogin: this.selectedOrLoggedInUser.login || this.selectedOrLoggedInUser.employeeNumber,
          source: this.sourceSystem,
        }).then(({ data }) => {
          this.setFormOptions(data);
        });
      }

      this.setLoading({ key: 'page', value: true });

      Promise.all([
        getCheckList,
        getFavoriteServices,
      ]).finally(() => {
        if (this.isFormDisabled) {
          this.setLoading({ key: 'page', value: false });

          if (this.formOptions.isNeedRedirect) {
            this.isRedirectModalShowed = true;
          }
        } else if (isResubmit) { // повторная подача
          this.model = createModelFromTicket(this.checklist);
          this.setLoading({ key: 'page', value: false });
        } else if (this.templateId) {
          this.$API.userTemplates.getUserTemplate(this.templateId).then(({ data }) => {
            // При изменении шаблонов использовать createModelFromTicket
            this.model = createModelFromTemplate(data.checklist.items);
          }).finally(() => {
            this.setLoading({ key: 'page', value: false });
          });
        } else {
          this.model = createDefaultModel(this.checklist);
          this.setLoading({ key: 'page', value: false });
        }
      });
    },
    saveTemplate() {
      this.$API.userTemplates.addUserTemplate({
        checklist: {
          title: this.baseChecklist.title, // удалить, когда SM тикеты исчезнут
          description: this.baseChecklist.description, // удалить, когда SM тикеты исчезнут
          phone: this.baseChecklist.phone, // удалить, когда SM тикеты исчезнут
          items: mappedFieldsForTemplate(this.model, this.checklist),
        },
        employeeId: this.loggedInUser?.userInfoByMasterSystems[this.sourceSystem].login,
        name: this.templateName,
        serviceId: this.serviceId,
        sourceSystem: this.sourceSystem,
      }).then(({ data }) => this.$EsmpNotify.$show(`Шаблон "${data?.name}" создан!`, 'success'));
    },

    onApproveRedirectModal() {
      this.onCancelRedirectModal();

      window.open(
        `https://sd.rt.ru/main/custom/event.html?type=ISSUES_CREATE&serviceId=${this.serviceId}`,
        '_blank',
      );
    },

    onCancelRedirectModal() {
      this.isRedirectModalShowed = false;
      this.$router.push('/');
    },

    async getNewsForCurrentService() {
      if (this.sourceSystem !== 'otrs1') return; // удалить, когда исчезнут SM заявки

      const { data: { content: news } } = await this.$API.news.getNews({
        serviceId: this.serviceId,
        state: 'actual',
        isPublic: true,
      });

      this.serviceNews = news.map((item) => ({
        ...pick(item, [
          'id',
          'title',
          'mainImageUrl',
        ]),
        text: `${item.previewContent}...`,
        url: getNewsArticlePath(item.id),
      }));
    },

    uploadFilesFromClipboard(event) {
      if (!this.firstUploaderControl) return;

      const blobs = pasteFilesFromClipboard(event);

      if (blobs.length) {
        const uploaderModel = this.model[this.firstUploaderControl.id] || [];
        this.$set(this.model, this.firstUploaderControl.id, [...uploaderModel, ...blobs]);
      }
    },

    showWatcherModal() {
      this.watcherModal = true;
    },

    updateWatcherList(watcherList) {
      this.watcherList = watcherList;
    },

    removeWatcher(watcherLogin) {
      this.watcherList = [...this.watcherList.filter((watcher) => watcher.login !== watcherLogin)];
    },

    async showWatcherInfoModal(watcher) {
      if (this.config?.userInfoAvailable) {
        this.watcherInfo = watcher.additionalInfo
          ? watcher.additionalInfo
          : await this.fetchUserAdditionalInfo(watcher.email);
        this.watcherInfoModal = true;
      }
    },
    getForm() {
      const filesForSend = [
        ...getFileList(this.model, this.checklist),
        ...this.baseChecklist.files,
        ...this.customServiceFiles,
      ];

      const form = {
        title: this.baseChecklist.title, // TODO: удалить, когда SM тикеты исчезнут
        description: this.baseChecklist.description, // TODO: удалить, когда SM тикеты исчезнут
        phone: this.baseChecklist.phone, // TODO: удалить, когда SM тикеты исчезнут
        serviceId: this.serviceId,
        sourceSystem: this.sourceSystem,
        serviceName: this.currentServiceName,
        contactUser: this.contactUser,
        formId: this.formId,
        formVersionId: this.formVersionId,
        isMassFail: this.isMassFail,
        isAffectsCompanyCustomers: this.isAffectsCompanyCustomers,
        ticketType: this.ticketType,
        checklistItems: mappedFields(this.model, this.checklist, undefined, filesForSend),
        customerUsers: [{
          id: this.selectedOrLoggedInUser?.userInfoByMasterSystems[this.sourceSystem].id,
          login: this.selectedOrLoggedInUser?.userInfoByMasterSystems[this.sourceSystem].login,
        }],
        searchLogId: this.searchLogId,
        formType: this.formType,
      };

      if (this.groupTicket) {
        Object.assign(form, {
          customerUsers: [
            ...this.customerUsers.map((el) => ({
              id: el.userInfoByMasterSystems[this.sourceSystem].id,
              login: el.userInfoByMasterSystems[this.sourceSystem].login,
            })),
          ],
        });
        if (this.isIncludedCurrentUserInGroupTicket) {
          form.customerUsers.push(this.contactUser);
        }
      }
      this.setForm(form);
      this.setFilesForSend(filesForSend);
    },
  },

  watch: {
    user: {
      handler(val) {
        if (val && this.hasCurrentService) this.getChecklist();
      },
      // Это необходимо,
      // чтобы не пришлось вызывать получение в created хуке
      immediate: true,
    },
  },

  async created() {
    Hub.$on('get-form-data-for-calc', this.getForm);

    this.getNewsForCurrentService();

    document.addEventListener('paste', this.uploadFilesFromClipboard);

    if (this.isRequestFrom) {
      const { data } = await this.$API.user.info(this.customerLogin);
      this.setSelectedUser(data);
    }
    if (this.checklistFormId) {
      this.getChecklist();
    }
  },

  beforeDestroy() {
    Hub.$off('get-form-data-for-calc', this.getForm);
    this.setForm(null);
    this.setFilesForSend([]);
    document.removeEventListener('paste', this.uploadFilesFromClipboard);
  },

  beforeRouteLeave(to, from, next) {
    if (this.searchLogId) {
      this.clearSearchLogId();
    }

    next();
  },
};
</script>

<style lang="scss" scoped>
.create-ticket {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  @include for-size(phone-portrait-down) {
    padding: 20px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &-sub-title {
      color: #B5B7C0;
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__desc {
    margin: 12px 0 0 0;
    color: #797E8B;
    font-size: 14px;
    line-height: 20px;
  }
  &__tags {
    margin: 20px 0 0 0;
    @include for-size(phone-portrait-down) {
      margin: 10px 0 0 0;
    }
    .esmp-tag {
      margin: 5px 8px 0 0;
    }
  }
  &__form {
    margin: 24px 0 0 0;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #E8E8EE;
    &--frame-mode {
      margin: 0;
    }
    &-group-checkbox {
      margin: 36px 0 0 0;
      @include for-size(phone-portrait-down) {
        margin: 18px 0 0 0;
      }
    }
    @include for-size(phone-portrait-down) {
      padding: 12px;
    }
  }
  &__add-watchers {
    margin: $base-gutter 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include for-size(phone-portrait-down) {
      margin: math.div($base-gutter, 2) 0;
    }
  }
  &__base-control {
    margin-top: 26px;
    .hm-form__element {
      &-hint {
        margin: 8px 0 0 0;
        font-size: 12px;
        line-height: 16px;
        transition: all ease 0.3s;
        &--error {
          color: $color-red;
        }
      }
      &-wrapper_error {
        .hm-form__element-label, .hm-form__element-hint {
          color: $color-red;
        }
        .esmp-upload-drag {
          border-color: #ff0c0ccc;
        }
        .esmp-input .esmp-input-element,
        .esmp-textarea__input,
        .esmp-select-head {
          border: 1px #ff0c0ccc solid;
        }
      }
    }
  }
  &__hint {
    color: $color-gray-status-op-60;
    margin-bottom: 10px;
  }
  &__fired-user {
    margin-top: 20px;
  }
  &__watchers-label {
    margin-bottom: $base-gutter;
  }
  &__watcher-list {
    display: flex;
    flex-direction: column;
    width: max-content;
  }
  &__watcher {
    margin-bottom: $base-gutter;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    .esmp-user-avatar {
      width: 32px;
      height: 32px;
      margin-right: $base-gutter;
    }
  }

  &__watcher-name {
    display: flex;
    align-items: center;
    margin-right: math.div($base-gutter, 2);
    transition: color $base-animation-time;

    @include hover(true) {
      color: $primary-color-hover;
    }
  }
}
.customer-users {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0 0 0;
  &__add-user {
    padding: 0;
  }
  &__element {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
    &-avatar {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
    &-fullname {
      font-size: 12px;
      line-height: 16px;
      margin: 2px 0 0 0;
    }
  }
}
.customer-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  &__search {
    margin: 20px 0 0 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0 0;
  }
  &__avatar {
    margin: 0 20px 0 0;
    width: 40px;
    height: 40px;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
    &-fio {
      margin: 0 16px 0 0;
    }
    &_no-access {
      color: $color-red;
    }
  }
  &__no-access {
    color: $color-red;
    @include for-size(phone-portrait-down) {
      font-size: 10px;
      line-height: 12px;
    }
  }
  &__email {
    display: inline-block;
    color: rgba(16, 24, 40, 0.4);
  }
  &__appointment-name {
    color: $color-primary-1-day;
  }
  &__email, &__appointment-name {
    font-size: 12px;
    line-height: 16px;
  }
  &__delete {
    cursor: pointer;
    margin-left: auto;
  }
}

.page-create-ticket {
  &__news {
    margin-bottom: 30px;

    .esmp-collapser__icon {
      color: $color-error-night;
    }
  }

  .ticket-news-item {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: math.div($gap, 2);
    }

    &__image {
      background: $color-grayscale-20;
      border-radius: $base-border-radius;
      margin-right: math.div($gap, 2);
      width: 120px;
      height: 60px;
      overflow: hidden;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
