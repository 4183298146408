<script>
import { isEmpty, cloneDeep } from 'lodash';
import { mapActions } from 'vuex';

import unicodeToWin1251 from '@/utils/unicodeToWin1251';
import dateFormat from '@/filters/dateFormat';
import { USER_INFO, TYPE } from '@/constants/external-training';

export default {
  name: 'ExternalTraining',
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    checklist: {
      type: Array,
      default: undefined,
    },
    config: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      dataPattern: [],
      dataList: [],
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    groupId() {
      const type = this.checklist.find((f) => f.id === this.config.typeId);
      const typeValue = this.model[this.config.typeId];

      if (type && typeValue) {
        const value = type.listValues.find((v) => v.id === typeValue);

        if (value) {
          const typeGroup = Object.keys(TYPE).find((t) => TYPE[t] === value.name);
          return typeGroup ? this.config[typeGroup] : null;
        }
      }
      return null;
    },
    currentGroup() {
      return this.model[this.groupId] || null;
    },
  },
  methods: {
    ...mapActions('ticket', ['setCustomServiceFiles']),
    getPattern() {
      const targetGroup = this.checklist.find((c) => c.id === this.groupId);
      if (targetGroup?.childs?.length) {
        const dataPattern = [];
        targetGroup.childs.forEach((c) => {
          // юзер разбивается на несколько полей
          if (c.class === 'search' && c.searchType === 'byEmployee') {
            Object.keys(USER_INFO).forEach((key) => {
              const dataPatternItem = {
                id: c.id,
                name: USER_INFO[key],
                class: c.class,
                type: key,
                value: null,
              };
              dataPattern.push(dataPatternItem);
            });
          } else if (c.class === 'group' && c.childs) {
            c.childs.forEach((gc) => {
              const dataPatternItem = {
                id: gc.id,
                parentGroupId: c.id,
                name: gc.name,
                class: gc.class,
                listValues: gc.listValues || null,
                value: null,
              };
              dataPattern.push(dataPatternItem);
            });
          } else if (c.class !== 'group') {
            const dataPatternItem = {
              id: c.id,
              name: c.name,
              class: c.class,
              listValues: c.listValues || null,
              value: null,
            };
            dataPattern.push(dataPatternItem);
          }
        });

        this.dataPattern = dataPattern;
      }
    },
    updateDataList() {
      const dataList = [];
      const pushToDataList = (model) => {
        const line = cloneDeep(this.dataPattern);
        return line.map((field) => {
          const newField = cloneDeep(field);
          let value;
          if (field.parentGroupId) {
            value = model[field.parentGroupId] ? model[field.parentGroupId][field.id] : null;
          } else {
            value = model[field.id] || null;
          }

          if (!isEmpty(value)) {
            if (field.class === 'search') {
              const user = value[0];
              if (user && user[field.type]) {
                newField.value = user[field.type].replace(/;/gi, ',');
              }
            } else if (field.class === 'select') {
              const textValue = field.listValues.find((v) => v.id === value);
              if (!isEmpty(textValue)) {
                newField.value = textValue.name;
              }
            } else {
              newField.value = value;
            }
          }

          return newField;
        });
      };

      if (this.currentGroup) {
        const firstLine = pushToDataList(this.currentGroup);
        dataList.push(firstLine);
        if (this.currentGroup?.addedGroups?.length) {
          this.currentGroup.addedGroups.forEach((g) => {
            const line = pushToDataList(g.value);
            dataList.push(line);
          });
        }
      }

      this.dataList = dataList;
    },
    makeCSV(arrData) {
      let data = '';
      const tableHead = this.dataPattern.map((i) => i.name).join(';');
      data += tableHead;
      arrData.forEach((childArr) => {
        data += '\r\n';
        data += childArr.map((i) => i.value || '').join(';');
      });

      data = unicodeToWin1251(data);

      const today = new Date();
      const file = new Blob([data], { type: 'text/csv;charset=utf-8' });
      file.lastModifiedDate = today;
      file.name = `список_сотрудников_${dateFormat(today, 'DD_MM_YYYY')}.csv`;
      file.isBlob = true;

      return file;
    },
  },
  watch: {
    model() {
      this.updateDataList();
    },
    dataList(value) {
      const file = this.makeCSV(value);
      this.setCustomServiceFiles([file]);
    },
    groupId() {
      this.getPattern();
      this.updateDataList(this.model);
    },
  },
  mounted() {
    this.getPattern();
    this.updateDataList(this.model);
  },
  render(h) {
    return h();
  },
};
</script>
